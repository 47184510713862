import React, { useState } from 'react';
import NewsletterSVG from './Newsletter.svg';
import { Button, Checkbox, Colors, HSpacer, Icon, Input, Text, VSpacer } from '@mqd/volt-base';
import useMarketo from './hooks/useMarketo';
import { submitMarketoForm } from './newsletterWidgetFunctions.js';
import * as s from './NewsletterWidget.module.css';

const NewsletterWidget = () => {
  const [pending, setPending] = useState(false);
  const [input, setInput] = useState('');
  //const [developerNewsletterChecked, setDeveloperNewsletterChecked] = useState(false);
  const [releaseNewsletterChecked, setReleaseNewsletterChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const formId = 1130;
  useMarketo({ formId: formId });

  const handleSubmit = () => {
    if (!input) return;
    setDisplaySuccessMessage(false);
    setErrorMessage('');
    setPending(true);

    const formInput = {
      Email: input,
      //subscriptionDeveloperNewsletterMonthly: developerNewsletterChecked ? 'yes' : 'no',
      subscriptionReleaseNotesNewsletterWeekly: releaseNewsletterChecked ? 'yes' : 'no',
    };

    submitMarketoForm(formId, formInput)
      .then(() => {
        setDisplaySuccessMessage(true);
      })
      .catch(() => {
        setErrorMessage('Enter a valid email address.');
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={s.container} data-testid="newsletter-widget">
      <Text type="h3" testId="signup-text">
        Get our newsletter for the latest updates
      </Text>
      <img data-testid="newsletter-icon" className={s.icon} src={NewsletterSVG} />
      <VSpacer factor={0.5} />
      {
        // Don't forget to remove the curly braces used to enclose the multi-line comments
        // if/when the time comes to restore this checkbox element!
        /* <Checkbox
        id="subscriptionDeveloperNewsletterMonthly"
        active={developerNewsletterChecked}
        label={'Developer newsletter (monthly)'}
        onClick={() => setDeveloperNewsletterChecked(!developerNewsletterChecked)}
      ></Checkbox> */
      }
      <Checkbox
        id="subscriptionReleaseNotesNewsletterWeekly"
        active={releaseNewsletterChecked}
        label={'Release notes newsletter (bi-weekly)'}
        onClick={() => setReleaseNewsletterChecked(!releaseNewsletterChecked)}
      ></Checkbox>
      <VSpacer factor={1} />

      <div className={s.form}>
        <Input
          width={228}
          placeholder="Email"
          disabled={pending}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          error={errorMessage}
          preventErrorPushdown={true}
          testId="newsletter-email-input"
        />
        <Button
          type="primary"
          disabled={pending}
          style={{ borderRadius: '0 4px 4px 0', marginLeft: '-3px' }}
          onClick={handleSubmit}
          testId="submit-button"
        >
          Subscribe
        </Button>
      </div>
      {displaySuccessMessage && <SuccessMessage />}
    </div>
  );
};

const SuccessMessage = () => (
  <div className={`${s.message}`} data-testid="newsletter-success-message">
    <Icon type="confirmed" overrideColor={Colors.statusSuccessColor} noHoverEffects factor={0} />
    <HSpacer factor={0.5} />
    <Text type="footnote">Thank you for subscribing to our newsletter.</Text>
  </div>
);

export default NewsletterWidget;
